import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Typography, Box, Grid, Paper } from '@material-ui/core'

import LoadingSkeleton from '@objects/loadingSkeleton'
import PostCard from '@objects/cards/postcard'

const useStyles = makeStyles((theme) => ({
  grid: {
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(9),
    paddingTop: theme.spacing(10),
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: '100%',
    overflowY: 'visible',
    overflowX: 'auto',
  },
  box: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(8),
  },
  cardwrapper: {
    display: 'flex',
  },
  item: {
    marginRight: theme.spacing(8),
  },
}))

function CardList({
  className,
  title,
  onActionClick,
  items,
  loading,
  noResultMessage,
}) {
  const classes = useStyles()

  return (
    <Grid container justify="center" className={className}>
      <Grid item xs={12} md={11} className={classes.grid}>
        <Box className={classes.box}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Paper className={classes.paper}>
          {!!!items.length && !loading && (
            <Box ml={8}>
              <Typography variant="h5">{noResultMessage}</Typography>
            </Box>
          )}
          {(!!items.length || loading) && (
            <LoadingSkeleton
              className={classes.cardwrapper}
              number={4}
              loading={loading}
            >
              {items.map((item, i) => (
                <PostCard
                  key={item.id}
                  onActionClick={onActionClick}
                  className={classes.item}
                  {...item}
                />
              ))}
            </LoadingSkeleton>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

CardList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  loading: PropTypes.bool,
  noResultMessage: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      author: PropTypes.string,
      copy: PropTypes.string,
      datetime: PropTypes.string,
      numberOfLikes: PropTypes.number,
      numberOfUserAnswers: PropTypes.number,
      editorApproved: PropTypes.bool,
      editorRejected: PropTypes.bool,
    })
  ),
}

export default CardList
