import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import { useIntl } from 'react-intl'
import CardList from '@components/cardlist'

import { postAdminFilter } from '@services/postservice'
import useSnackbar from '@hooks/useSnackbar'

export const frontmatter = {
  title: 'Übersicht',
}

const useStyles = makeStyles((theme) => ({
  cardlist: {
    marginTop: theme.spacing(8),
  },
}))

function RootIndex({ data }) {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleSnackbar } = useSnackbar()

  const isMountedRef = useRef(null)
  const [newPostList, setNewPostList] = useState([])
  const [newAnswerList, setNewAnswerList] = useState([])
  const [postLoading, setPostLoading] = useState(true)
  const [answerLoading, setAnswerLoading] = useState(true)

  useEffect(() => {
    isMountedRef.current = true
    getNewPosts()
    // const interval = setInterval(() => {
    //   if (isMountedRef.current) getNewPosts()
    // }, 60000)
    return () => {
      isMountedRef.current = false
      // clearInterval(interval)
    }
  }, [])

  function getNewPosts() {
    postAdminFilter(0, 20, false).then((data) => {
      if (!data.apiError) {
        if (isMountedRef.current && data.items?.length > 0) {
          const dataArray = data.items
          setNewPostList(
            dataArray.filter(
              (item) => !item.editorApproved && !item.editorRejected
            )
          )
          setPostLoading(false)
          setNewAnswerList(
            dataArray.filter((item) => item.hasNotApprovedAnswersOrComments)
          )
          setAnswerLoading(false)
        }
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function onActionClickHandler() {
    if (!isMountedRef.current) return
    setPostLoading(true)
    setAnswerLoading(true)
    getNewPosts()
  }

  return (
    <>
      <CardList
        className={classes.cardlist}
        onActionClick={onActionClickHandler}
        items={newPostList}
        loading={postLoading}
        noResultMessage={intl.formatMessage({ id: 'overview.nonewposts' })}
        title={intl.formatMessage({
          id: 'overview.newposts',
        })}
      />
      <Box mb={25} />
      <CardList
        className={classes.cardlist}
        onActionClick={onActionClickHandler}
        items={newAnswerList}
        loading={answerLoading}
        noResultMessage={intl.formatMessage({ id: 'overview.nonewanswers' })}
        title={intl.formatMessage({
          id: 'overview.newanswers',
        })}
      />
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex
