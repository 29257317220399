import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { red } from '@material-ui/core/colors'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import copy from 'copy-to-clipboard'

import { FormattedMessage } from 'react-intl'
import {
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
} from '@material-ui/core'

import { approvePost, declinePost } from '@services/postservice'
import EditorIconRow from '@objects/editoriconrow'
import Button from '@objects/button'
import Copy from '@objects/copy'
import ContextMenu from '@objects/contextmenu/'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '350px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '&.rejected': {
      backgroundColor: red['50'],
    },
  },
  cardactionarea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3),
    '&.rejected': {
      backgroundColor: red['800'],
    },
  },
  info: {
    color: theme.palette.grey.main,
    fontSize: '12px',
  },
  cardactions: {
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
  },
}))

function PostCard({
  className,
  id,
  title,
  text,
  userName,
  userEmail,
  timestamp,
  editorApproved,
  editorRejected,
  onActionClick,
  hasNotApprovedAnswersOrComments,
  markReason,
  rejectReason,
  isPublished,
  isDeleted,
  isDraft,
}) {
  const classes = useStyles()
  const dial = useDialog()

  const ContextMenuCommonProps = {
    id: id,
    userName: userName,
    userEmail: userEmail,
    type: 'article',
    questionurl: `/beitraege/${id}`,
    mailto: `subject=${title?.replace(/<\/?[^>]+>/gi, '')}&body=${text
      ?.replace(/&nbsp;/gi, ' ')
      .replace(/<\/?[^>]+>/gi, '')}`,
    mark: !!markReason,
    onRemoveMark: onActionClick,
    onMark: onActionClick,
    onCopy: onCopyClick,
    onBann: () => {},
  }

  const { toggleSnackbar } = useSnackbar()

  function onCopyClick() {
    copy(text, { format: 'text/html' })
  }

  function onClick() {
    navigate(`/beitraege/${id}`)
  }

  function onApproveClick() {
    approvePost(id).then((data) => {
      if (!data?.apiError) {
        if (onActionClick) onActionClick()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function onDecline({ reason, bann, bannreason }) {
    declinePost(id, reason, bann, bannreason, 'article').then((data) => {
      if (!data.apiError) {
        if (onActionClick) onActionClick()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  return (
    <Box position="relative" className={className}>
      <Card
        elevation={3}
        className={classNames(classes.card, { rejected: editorRejected })}
      >
        <CardActionArea className={classes.cardactionarea} onClick={onClick}>
          <Box
            className={classNames(classes.toolbar, {
              rejected: editorRejected,
            })}
          >
            <Typography variant="h5">{title}</Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Typography className={classes.info}>{userName}</Typography>
                <Typography className={classes.info}>{userEmail}</Typography>
              </Box>
              <Typography className={classes.info}>
                {moment(timestamp).locale('de').format('DD.MM.YYYY HH:mm')}
              </Typography>
            </Box>
          </Box>
          <CardContent>
            <Copy html={text} truncate={30} />
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardactions}>
          <EditorIconRow
            newanswer={hasNotApprovedAnswersOrComments}
            marked={!!markReason}
            markedreason={markReason}
            approved={editorApproved}
            rejected={!!editorRejected}
            rejectedreason={rejectReason}
            notpublished={!isPublished}
            deleted={isDeleted}
          />
          {!editorApproved && !editorRejected && !isDraft && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onApproveClick}
              >
                <FormattedMessage id="button.accept" />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() =>
                  dial.openDialog('decline', { onSubmit: onDecline })
                }
              >
                <FormattedMessage id="button.decline" />
              </Button>
            </>
          )}
          <ContextMenu {...ContextMenuCommonProps} />
        </CardActions>
      </Card>
    </Box>
  )
}

PostCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  onActionClick: PropTypes.func,
  title: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  text: PropTypes.string,
  timestamp: PropTypes.string,
  numberOfLikes: PropTypes.number,
  numberOfUserAnswers: PropTypes.number,
  editorApproved: PropTypes.bool,
  editorRejected: PropTypes.bool,
  hasNotApprovedAnswersOrComments: PropTypes.bool,
  markReason: PropTypes.string,
  rejectReason: PropTypes.string,
  isPublished: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isDraft: PropTypes.bool,
}

export default PostCard
